<template>
  <TheNavbar/>
  <router-view></router-view>
  <TheFooter/>
</template>

<script setup>
import TheNavbar from "@/pages/components/TheNavbar.vue";
import {RouterView} from "vue-router";
import {defineAsyncComponent} from "vue";
const TheFooter = defineAsyncComponent(()=>import('@/pages/components/TheFooter.vue'))
</script>

