<template>
  <div class="d-flex justify-center partners align-center">
    <carousel :items-to-show="amount" autoplay="1" :wrap-around="true" transition="2000" class="w-100">
      <slide v-for="slide in slides" v-bind:key="slide" class="company-logo">
        <component :is="slide.object" class="company-logo__image"/>
      </slide>
    </carousel>
  </div>
</template>
<script setup>
import 'vue3-carousel/dist/carousel.css'
import {Carousel, Slide} from "vue3-carousel";
import GfssCompany from "@/pages/components/partners/GfssCompany.vue";
import QajService from "@/pages/components/partners/QajService.vue";
import SuCompany from "@/pages/components/partners/SuCompany.vue";
import GakCompany from "@/pages/components/partners/GakCompany.vue";
import KazaviaspasCompany from "@/pages/components/partners/KazaviaspasCompany.vue";
import UtoCompany from "@/pages/components/partners/UtoCompany.vue";
import OlympicCompany from "@/pages/components/partners/OlympicCompany.vue";
import ZqaiCompany from "@/pages/components/partners/ZqaiCompany.vue";
import ElordaEcosystem from "@/pages/components/partners/ElordaEcosystem.vue";
import IuthCompany from "@/pages/components/partners/IuthCompany.vue";
import AstanaOpera from "@/pages/components/partners/AstanaOpera.vue";
import QazidustryCompany from "@/pages/components/partners/QazidustryCompany.vue";
import {onMounted, ref} from 'vue';

const amount = ref(10)
const slides = [
  {
    object: GfssCompany,
    title: 'АО "Государственный фонд социального страхования"'
  },
  {
    object: QajService,
    title: 'АО Национальная компания "КазАвтоЖол"'
  },
  {
    object: SuCompany,
    title: 'АО "Компания по страхованию жизни "Государственная аннуитетная компания" '
  },
  {
    object: GakCompany,
    title: 'АО "Компания по страхованию жизни "Государственная аннуитетная компания" '
  },
  {
    object: KazaviaspasCompany,
    title: 'АО “Казавиаспас”'
  },
  {
    object: UtoCompany,
    title: ''
  },
  {
    object: OlympicCompany,
    title: 'РГП на ПХВ "Национальный центр тестирования"'
  },
  {
    object: ZqaiCompany,
    title: '«Институт законодательства и правовой информации Республики Казахстан» Министерства юстиции Республики Казахстан'
  },
  {
    object: ElordaEcosystem,
    title: 'ELORDA ECO SYSTEM'
  },
  {
    object: IuthCompany,
    title: 'Международный Университет Туризма и Гостеприимства'
  },
  {
    object: AstanaOpera,
    title: '"State Opera and Ballet Theatre "Astana Opera" '
  },
  {
    object: QazidustryCompany,
    title: 'АО "КЦИЭ "QazIndustry"'
  }]

onMounted(
    () => {
      if(window.innerWidth>=1100){
        amount.value=10
      }
      else if(window.innerWidth<1100 && window.innerWidth>=900){
        amount.value=7
      }
      else if(window.innerWidth<900 && window.innerWidth>=600){
        amount.value=4
      }
      else{
        amount.value=3
      }
    }
)
</script>