<template>
  <div class="d-flex justify-center hero position-relative">
    <div class="block-width d-flex justify-between align-center">
      <div class="d-flex flex-col justify-center hero-text" style="width: 415px; min-height: 310px">
        <div class="position-relative mb-2">
          <h1>{{ $t('control_documents') }} <span class="c-primary">{{$t('control_documents_2')}}</span></h1>
          <img src="@/assets/images/marker-underline.svg" class="underline-image"/>
        </div>
        <p class="mb-15 text-md">{{$t('hero_text')}}</p>
        <TheButtonPrimary :content="$t('contact_us')" :is-plain="true" @click="goTo"/>
      </div>
    </div>
    <img
        style="position: absolute; max-width: 800px;" class="landing-hero-img"
        src="https://placehold.co/3200x800/png"
        srcset="
          @/assets/images/landing-document-resized_w-450.webp 800w,
          @/assets/images/landing-document-resized.webp 1600w,
        "
        alt="hero image"
    />
  </div>
</template>
<script setup>
import TheButtonPrimary from "@/components/TheButton/TheButtonPrimary.vue";
import {useRouter} from "vue-router";

const router = useRouter()
const goTo = () => {
  router.push({name: 'contact-us', params: {message: 'message_text_1'}})
}
</script>