<template>
  <select @change="handleChange" v-model="currentLocale" class="language-select">
    <option
      :key="option"
      :selected="option.label==='ru'"
      v-for="option in options"
      :label="$t(option.label)"
      :value="option.value"
      class="c-white"
    >
      {{$t(option.label)}}
    </option>
  </select>
</template>
<script setup>
import { ref } from 'vue'

const currentLocale = 'ru'
const options = ref([
  { label: 'ru', value: 'ru' },
  { label: 'kz', value: 'kk' }
])
const handleChange = () =>{
  // localStorage.setItem('locale') || 'ru';
}
</script>
