<template>
  <div class="d-flex justify-center choose-module">
    <div class="block-width">
      <h1 class="mt-0 mb-375 text-center">{{ $t('choose_module')}}</h1>
      <div class="d-flex justify-between card card__gray align-center mb-1" ref="target">
        <div class="card__content" id="card1">
          <div class="tag tag__blue d-flex align-center mb-05">
            <img
              src="@/assets/images/icons/file-icon.svg"
              alt="icon"
              class="mr-05"
            />
            {{$t('office_work')}}
          </div>
          <h3 class="mt-0 mb-05">{{ $t('ch_module_1') }}</h3>
          <p>
            {{ $t('ch_module_2') }}
          </p>
        </div>
        <div class="position-relative card__image">
          <img
            src="@/assets/images/card-images/stepper.svg"
            id="card1-1"
            class="mb-1 lines"
            width="538"
            height="49"
            style="position: relative; right: -10px; aspect-ratio: auto 538 / 49;"
            alt="card image"
            loading="lazy"
          />
          <img
            src="@/assets/images/card-images/card-1-big.svg"
            id="card1-2"
            alt="card image"
            loading="lazy"
            width="580"
            height="299"
            style="aspect-ratio: auto 580 / 299;"
          />
        </div>
      </div>
      <div class="d-flex justify-between mb-1 card-row">
        <div class="card card__orange w-58" ref="target2">
          <div class="card__content" id="card2">
            <div class="tag tag__orange d-flex align-center mb-05">
              <img
                src="@/assets/images/icons/people.svg"
                class="mr-05"
                alt="icon"
            />
              {{$t('hr')}}
            </div>
            <h3 class="mt-0 mb-05 w-80">
              {{ $t('ch_module_3') }}
            </h3>
            <p class="w-80">{{ $t('ch_module_4') }}</p>
          </div>
          <img
            id="card2-1"
            src="@/assets/images/card-images/card-2-big.svg"
            alt="card-2"
            width="627"
            height="212"
            class="w-100 position-relative"
            style="bottom: -4px; aspect-ratio: auto 627 / 212;"
            loading="lazy"
          />
        </div>
        <div class="card card__blue w-40">
          <div class="card__content" id="card2-2">
            <div class="tag tag__blue mb-05 d-flex align-center">
              <img
                src="@/assets/images/icons/percent.svg"
                alt="percent"
                class="mr-05"
              />
              {{ $t('contracts_control') }}
            </div>
            <h3 class="mt-0 mb-05">{{ $t('ch_module_5') }}</h3>
            <p>{{ $t('ch_module_6') }}</p>
          </div>
          <img
            src="@/assets/images/card-images/card-3-big.svg"
            id="card2-3"
            alt="card image"
            width="459"
            height="209"
            style="aspect-ratio: auto 459 / 209;"
          />
        </div>
      </div>
      <div class="d-flex justify-between mb-1 card-row">
        <div class="card card__pink w-40" ref="target3">
          <div class="card__content" id="card3">
            <div class="tag tag__pink mb-05 d-flex align-center">
              <img
                src="@/assets/images/icons/copy.svg"
                alt="copy"
                class="mr-05"
              />
              {{ $t('projects_control') }}
            </div>
            <h3 class="mt-0 mb-05">{{ $t('ch_module_7') }}</h3>
            <p>
              {{ $t('ch_module_8') }}
            </p>
          </div>
          <img
            src="@/assets/images/card-images/card-4-big.svg"
            id="card3-1"
            class="pink-img"
            alt="card image"
            loading="lazy"
            width="459"
            height="170"
            style="aspect-ratio: auto 459 / 170;"
          />
        </div>
        <div class="card card__green w-58">
          <div class="card__content" id="card3-2">
            <div class="tag tag__green mb-05 d-flex align-center">
              <img
                src="@/assets/images/icons/case.svg"
                alt="copy"
                class="mr-05"
              />
              {{ $t('trip_control') }}
            </div>
            <h3 class="mt-0 mb-05">{{ $t('ch_module_9') }}</h3>
            <p>
              {{ $t('ch_module_10') }}
            </p>
          </div>
          <img
            src="@/assets/images/card-images/card-5-big.svg"
            alt="card-5"
            class="w-100 position-relative"
            id="card3-3"
            style="bottom: -10px; aspect-ratio: auto 631 / 202;"
            loading="lazy"
            width="631"
            height="202"
          />
        </div>
      </div>
      <div class="d-flex justify-between card card__gray align-center mb-1" ref="target4">
        <div class="card__content" id="card4">
          <div class="tag tag__blue d-flex align-center mb-05">
            <img
              src="@/assets/images/icons/file-icon.svg"
              alt="icon"
              class="mr-05"
            />
            {{ $t('skud') }}
          </div>
          <h3 class="mt-0 mb-05">{{ $t('ch_module_11') }}</h3>
          <p>
            {{ $t('ch_module_12') }}
          </p>
        </div>
        <div class="position-relative card__image d-flex flex-col">
          <img
            src="@/assets/images/card-images/lines.svg"
            class="mb-1 lines"
            id="card4-1"
            style="position: relative; right: -100px; aspect-ratio: auto 245 / 94;"
            alt="card image"
            loading="lazy"
            width="245"
            height="94"
          />
          <img
            src="@/assets/images/card-images/card-6-big.svg"
            class="card4"
            id="card4-2"
            alt="card image"
            loading="lazy"
            width="580"
            height="299"
            style="aspect-ratio: auto 580 / 299;"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useElementVisibility } from '@vueuse/core'
import { ref, watch} from 'vue'

const target = ref(null)
const target2 = ref(null)
const target3 = ref(null)
const target4 = ref(null)

const targetIsVisible = useElementVisibility(target)
const targetIsVisible2 = useElementVisibility(target2)
const targetIsVisible3 = useElementVisibility(target3)
const targetIsVisible4 = useElementVisibility(target4)

watch(
    () => targetIsVisible.value,
    () => {
      document.getElementById('card1').classList.add('animation-top')
      document.getElementById('card1-1').classList.add('animation-top')
      document.getElementById('card1-2').classList.add('animation-top')
    }
)
watch(
    () => targetIsVisible2.value,
    () => {
      document.getElementById('card2').classList.add('animation-right')
      document.getElementById('card2-1').classList.add('animation-right')
      document.getElementById('card2-2').classList.add('animation-left')
      document.getElementById('card2-3').classList.add('animation-left')
    }
)
watch(
    () => targetIsVisible3.value,
    () => {
      document.getElementById('card3').classList.add('animation-right')
      document.getElementById('card3-1').classList.add('animation-right')
      document.getElementById('card3-2').classList.add('animation-left')
      document.getElementById('card3-3').classList.add('animation-left')
    }
)
watch(
    () => targetIsVisible4.value,
    () => {
      document.getElementById('card4').classList.add('animation-top')
      document.getElementById('card4-1').classList.add('animation-top')
      document.getElementById('card4-2').classList.add('animation-top')
    }
)
</script>