<template>
  <TheHero/>
  <ThePartners/>
  <ChooseModule/>
  <WhatWeCan/>
  <PaymentPlan/>
  <ContactUs/>
</template>
<script setup>
import {defineAsyncComponent} from "vue";

import  TheHero from "@/pages/components/TheHero.vue"
import  ThePartners from '@/pages/components/ThePartners.vue'
import  ChooseModule from '@/pages/components/TheChooseModule.vue'
const  WhatWeCan = defineAsyncComponent(()=> import('@/pages/components/WhatWeCan.vue'));
const  PaymentPlan = defineAsyncComponent(()=> import('@/pages/components/PaymentPlan.vue'));
const  ContactUs = defineAsyncComponent(()=> import('@/pages/components/ContactUs.vue'));
</script>