import { createI18n } from 'vue-i18n'

function loadLocaleMessages() {

  // const locales = import.meta.glob('../assets/translations/*.json')
  // const messages = {}
  // for (const key in locales) {
  //   const matched = key.match(/([A-Za-z0-9-_]+)\./i)
  //   if (matched && matched.length > 1) {
  //     const locale = matched[1]
  //     messages[locale] = locales[key].default
  //   }
  // }
  return {
    ru:require('../assets/translations/ru.json'),
    kk:require('../assets/translations/kk.json')
  }
}

function RuLocalePluralizationRule(choice, choicesLength) {
  if (choice < 0) {
    choice = choice * -1
  }
  if (choice === 0) {
    return 0
  }
  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1

  if (choicesLength < 4) {
    return !teen && endsWithOne ? 1 : 2
  }
  if (!teen && endsWithOne) {
    return 1
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return choicesLength < 4 ? 2 : 3
}

const messages = loadLocaleMessages()
const locale = localStorage.getItem('locale') || 'ru'
const i18n = new createI18n({
  legacy: false,
  locale,
  globalInjection: true,
  messages,
  fallbackLocale: 'ru',
  pluralizationRules: {
    ru: RuLocalePluralizationRule
  }
})

export default i18n
